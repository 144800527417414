/* Provide sufficient contrast against white background */
#root {
    background: #f6f6f6;
}

.layout {
    min-height: 100vh;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

header {
    position: fixed;
    z-index: 1000;
    width: 100%;
    background: white;
}

.login form {
    background: white;
    padding: 60px 30px 30px 30px;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 15%);
}

.sidemenu {
    min-width: 230px;
    background: white;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 15%);
    padding: 30px;
    position: fixed;
    top: 60px;
    bottom: 10px;
    z-index: 10;
}

.no-sidemenu {
    margin: auto !important;
    background: transparent !important;
    box-shadow: none !important;
}

.sidemenu ul li.active {
    position: relative;
}

    .sidemenu ul li.active::before {
        content: "";
        position: absolute;
        left: 0;
        top: 7px;
        height: 25px;
        border-left: 3px solid red;
    }

.sidemenu ul li a {
    cursor: pointer;
    color: #6e84a3 !important;
}

.sidemenu ul li.active a,
.sidemenu ul li:hover a {
    color: black !important;
}

.content {
    padding: 65px 5px 15px 5px;
    margin: 60px 0px 10px 0px;
    background: white;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 15%);
}

.loading {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1000;
    background: rgba(0,0,0,0.0);
    display: flex;
    align-items: center;
    justify-content: center;
}

.lds-spinner {
    /*top: 35%;
    left: 35%;*/
}

.options {
    border: 1px solid #ced4da;
}

.qa-header ul {
    gap: 10px;
}

.qa-header li {
    list-style: none;
}

.qa-header ul:first-child li {
    cursor: pointer;
}

.qa-header .active {
    color: blue;
}

.to-qa-detail {
    display: flex;
    padding: 0.5rem;
    text-decoration: none;
}

.qa-alert {
    padding: 10px 30px;
    position: fixed;
    right: 10px;
    background: blueviolet;
    color: white;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 15%);
    border-radius: 3px;
}

.created-by-comment {
    background: aliceblue;
    font-size: 0.8em;
}

.qas-table td {
    white-space: nowrap;
}

.page-no button:focus {
    box-shadow: none;
}

.page-no .active {
    color: #0366d6;
    font-weight: bold;
    font-size: large;
}

.empty-photo img {
    width: 75px;
}

.category .label {
    min-width: 100px;
}

.ct-new {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1000;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

    .ct-new .card {
        width: 350px;
    }

.checkpoint .action a {
    text-decoration: none;
    cursor: pointer;
    color: #0366d6;
}

.checkpoints {
    max-height: 450px;
    overflow: auto;
}

.qa-img-thumbnail {
    position: relative;
    width: 80px;
    height: 80px;
    padding: 0.5rem;
}

.qa-img-thumbnail img {
    width: 100%;
    height: 100%;
}

.qa-img-cancel {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.0);
    padding: 0.25rem;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    opacity: 0;
}
.qa-img-cancel:hover {
    background-color: rgba(0,0,0,0.4);
    opacity: 1;
}

.qa-img-cancel img {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0;
}

@media (min-width: 576px) {
    .content {
    }
}

@media (min-width: 768px) {
    .content {
        padding: 65px 20px 20px 20px;
        margin: 60px 0px 10px 250px;
    }

    .header-toggle-btn {
        display: none;
    }
}

@media (min-width: 992px) {
    .qa-header ul {
        gap: 3rem;
    }
}

@media (max-width: 767.98px) {
    .open {
        display: none;
    }
}
