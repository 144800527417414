a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.avatar {
    display: block;
    text-align: center;
    text-decoration: none;
    color: black;
}
.avatar img {
    border-radius: 50%;
    width: 60px;
}
